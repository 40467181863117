import React, { Component } from 'react';

class Resume extends Component {
  render() {
    if(this.props.data){
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <ul>
              <li> <p> {work.description1} </p></li>
              <li> <p> {work.description2} </p></li>
              <li> <p> {work.description3} </p></li>
              <li> <p> {work.description4} </p></li>
              <li> <p> {work.description5} </p></li>
            </ul>
        </div>
      })
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>

      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>
      <div className="skill-list">
        <h5> Front-end </h5>
        <ul>
          <li> React JS </li>
          <li> React Native </li>
          <li> Redux </li>
          <li> Javascript </li>
          <li> HTML </li>
          <li> CSS </li>
        </ul>
      </div>
      <div className="skill-list">
        <h5> Back end</h5>
        <ul>
          <li> Java </li>
          <li> .NET / C# </li>
          <li> C / C++ </li>
          <li> Express JS </li>
          <li> Node JS </li>
          <li> SQL </li>
          <li> MongoDB </li>
        </ul>
      </div>
      <div className="skill-list">
        <h5> Data Science </h5>
        <ul>
          <li> R </li>
          <li> Python </li>
          <li> Apache Spark </li>
          <li> Hadoop </li>
        </ul>
      </div>
      <div className="skill-list">
        <h5> Others </h5>
        <ul>
          <li> Docker </li>
          <li> Kubernetes </li>
          <li> Google Cloud Platform (GCP)</li>
          <li> Git </li>
          <li> Jira </li>
          <li> Team Foundation Server </li>
        </ul>
      </div>
			</div>
   </section>
    );
  }
}

export default Resume;