import React, { Component } from 'react';


class About extends Component {
   constructor(props) {
      super(props)
   }
   
   render() {
      const {bio, secondaryBio, name, phone, email} = this.props
      console.log("Bio" + bio);
      return (
         <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic"  src={"images/kevin_new.jpg"} alt="Profile Pic" />
            </div>
            <div className="nine columns main-col">
               <h2>About Me</h2>
               <p>
               A Software Engineer at Workday with over 3 years of experience building out the Next-Gen Campaigns product with a scalable micro-service architecture. 
               Technologies utilized include MySQL, Spring Boot, Java / Kotlin, along with React. 
               I design and write code with a strong focus in security, being the Security Champion of the team.
               <br/>
               <br/>
               I love to expand my network and to get to know more people. So please feel free to shoot me an email or text if you want to work on a new project or just to have a coffee chat!
               </p>
               <div className="row">
                  <div className="columns contact-details">
                     <h2>Contact Details</h2>
                     <p className="address">
                        <span>Kevin Ismantara</span><br />
                        <span>8247 16th Ave<br />
                              Burnaby, BC, V3N 1R9
                     </span><br />
                        <span>+1(778)-838-6796</span><br />
                        <span>ismantara.kevin@yahoo.com</span>
                     </p>
                  </div>
               </div>
            </div>
         </div>

      </section>
      );
   }
}

export default About;
